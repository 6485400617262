* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.icon-dropthere {
  position: absolute;
  right: 20px;
  top: 52%;
  font-size: 25px;
  color: gray;
}
.drop-icon {
  position: relative;
}

.kriti:hover {
  background: red;
  z-index: 1;
}
.aboutindex {
  z-index: 1 !important;
}

.bathroomconceptdiv {
  position: relative;
}
.innerdivconcept {
  /* border: solid gray 2px; */
  padding: 3%;
  position: absolute;
  top: 30%;
  left: 38%;
  color: white;
  background: black;
  opacity: 0.8;

  /* transform: translate(-50%, -50%); */
}
.innerdivheading {
  font-size: 2vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.innerbtnconcept {
  background: black !important;
  color: white;
  border: gray 1px solid;
  padding: 5px 10px 5px 10px;
  margin: 10px 10px 10px 0px;
}
@media screen and (max-width: 480px) {
  .innerdivheading {
    font-size: 15px;
  }
  .innerdivconcept {
    top: 20%;
    left: 20%;
  }
  .innerbtnconcept {
    font-size: 2vw;
    padding: 2.5px 5px 2.5px 5px;
  }
}

@media screen and (max-width: 480px) {
  .flexx {
    display: block;
  }
  .aboutbathroomimage {
    display: flex;
    padding-top: 2vw;
    justify-content: center;
  }
}

.ArivalHeading {
  font-family: 'Poppins', sans-serif;
  font-size: 35px;
  font-weight: 700;
}
.btnAboutus {
  background: none;
  border: white solid 1px;
  padding: 7px;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  height: 32px;
  width: 120px;
}
.zxczxc {
  padding-top: 20px;
  padding-bottom: 20px;
}
.Arivaldiv {
  padding-top: 50px;
  padding-bottom: 40px;
  background: #f2fcfb;
}
.flexxx {
  display: flex;
  justify-content: center;
}
.width {
  width: 75%;
  padding-left: 18%;
}
.borderr {
  border: white solid 5px;
}
.white {
  color: white;
  font-family: 'Poppins', sans-serif;
}
.white16 {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}
.white35 {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 35px;
}
.font-family {
  font-family: 'Poppins', sans-serif;
}
.heading {
  padding-top: 20px;
  display: flex;
}
.brandimage {
  height: 71px;
}
.texcen {
  display: flex;
  justify-content: center;
}
.cxzcxz {
  left: -500px;
  width: 150%;
  height: 500%;
  background: #000000c9;
  -webkit-transform: rotate(160deg);
  transform: rotate(160deg);
}
.ourbrandcolour {
  padding-bottom: 30px;
  background-color: #7777;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linktaghome {
  text-decoration: none;
}
.About {
  background-image: url('asset/image/Aboutus.png');
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 70px;
  background-attachment: fixed;
  overflow: hidden;
  position: relative;
}
.border {
  border: solid black 3px;
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
}
.border1 {
  margin: -38px;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 43px;
}
.About1 {
  position: relative;
  background-image: url('asset/image/Wellness.png');
  background-size: cover;
}
.marginn {
  margin: 5px;
}
.loyalityimage {
  max-width: 82%;
}
.bottom-left {
  position: absolute;
  top: 95%;
}
.WellNess {
  padding: 25px;
  background-image: url('asset/image/Wellness.png');
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 70px;
  opacity: 0.9;
}
.image1 {
  width: 80%;
  height: auto;
}
.bottom-left {
  bottom: 8px;
  left: 16px;
}
.height {
  height: 450px;
}

@media only screen and (max-width: 600px) {
  .flexxx {
    display: block;
  }
  .flex {
    display: block;
  }
  .border1 {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

figure .placeholder-glow .category-placeholder {
  width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0 !important;
    padding: 0 !important;
}

figure .placeholder-glow .category-placeholder span {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
