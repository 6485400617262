.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: gray !important;
  width: 22px;
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 1 !important;
}

.banner-homepage-sk .carousel {
  height: 100% !important;
}

.banner-homepage-sk {
  height: 112px;
}

@media only screen and (min-width: 361px) {
  .banner-homepage-sk {
    height: 125px;
  }
}

@media only screen and (min-width: 480px) {
  .banner-homepage-sk {
    height: 600px;
  }
}