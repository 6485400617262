.flex-tollfree span {
  cursor: pointer;
}
.pointercss {
  cursor: pointer;
}
.ml-1 {
  margin-left: 5px;
}
.positionofsearch {
  position: 'absolute';
  top: '35px';
  right: 8;
}
.sticky-wrapper {
  background: #d1ab66;
}
.pointer {
  cursor: pointer;
}
.pointer:hover {
  color: #3e3d3d !important;
}
.margin5px {
  font-size: 24px;
  margin-right: -8px;
}
.seaxch {
  background: none;
  border: none;
  font-size: 24px;
}
@media screen and (max-width: 480px) {
  .heightheader {
    height: 72px;
  }
  .positionofsearch {
    position: 'absolute';
    top: '35px';
    left: auto;
  }
}

.logo-international{
  width: 50%;
}