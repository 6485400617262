.btn-product-details {
  background-color: lightgoldenrodyellow;
  margin-top: -10px;
  border: lightgray;
  font-size: 12px;
  width: 100px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexcolorproduct {
  display: flex;
  align-items: center;
}
.product-heart {
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  padding: 9px;
}
.txtdec {
  text-decoration: none;
  color: black;
}
